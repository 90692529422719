import React,{useState} from 'react'
import Link from 'gatsby'
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-two'
import BlockContent from '@sanity/block-content-to-react'
import Hero from "./product/hero";
import {Container, Row, Col} from '../components/ui/wrapper'

import {ArticleImage,ArticleH1,ArticleIntro,ArticleContent,ArticleImageText,Aside,AsideTransparentBorder} from "./article.style"
import Assurance from "./product/assurance"
import { CaseStudyTextWrap } from "./product/assurance/assurance.style"
import Heading from "../components/ui/heading"
import Text from "../components/ui/text"
import AboutServiceWrap from "../containers/index/about-service-wrap"
import AboutArea from "../containers/index/about-area"
import { graphql, useStaticQuery } from "gatsby"
import Aggregator from "./product/aggregator"
import ProductForm from "../components/forms/product-form"




const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    )
  }
}

const Product = ({location,pageContext}) =>{

  const [formActive,setFormActive] = useState(false)

  //console.log(pageContext)

  const data = useStaticQuery(graphql`
        query
            {
            products: allSanityGuaranteeProduct(filter: {active: {eq: true}}, sort: {fields: name, order: ASC}){
                edges{
                    node{
                        _id
                          explanation
                          name
                          bygg
                          slug {
                            current
                          }
                          image {
                            asset {
                              fixed(width:225, height:145){
                                    ...GatsbySanityImageFixed
                                }
                            }
                          }
                          introduction
                          industry {
                            name
                          }
                          provider{
                            name,
                            introduction,
                            logo{
                              asset {
                                fixed(width:200){
                                      ...GatsbySanityImageFixed
                                  }
                              }
                            },
                            can_use_logo,
                            slug{
                              current
                            },
                            homepage
                          }
                          substitute_products
                          alt_names
                    }
                }
            }
        
            industries: allSanityGuaranteeIndustry(filter: {bygg: {eq: true}}, sort: {fields: name,order: ASC}) {
                edges {
                    node {
                        name
                        bygg
                        slug {
                            current
                        }
                    }
                }
            }
        }
    `)

  return(
    <Layout
        location={location}
        >
      <SEO
        title={((pageContext.substitute_products && pageContext.substitute_products[0] && pageContext.substitute_products[0].length > 0)?pageContext.substitute_products[0]+" | ":"")+"Få uforpliktende tilbud fra flere aktører"}
        description={(pageContext.name
                      + " er likestilt med "+((pageContext.substitute_products && pageContext.substitute_products[0] && pageContext.substitute_products[0].length > 0)?pageContext.substitute_products[0].toLowerCase()+"er fra bank ":" bankgaranti "))
                      + "og frigjør bedriftens kapital. Vi innhenter pristilbud fra flere aktører."}
        image={(pageContext.image?pageContext.image.asset.url:"")}
        pathname={pageContext.slug}
      />
        <Header
          product={(pageContext.substitute_products && pageContext.substitute_products[0] && pageContext.substitute_products[0].length > 0)?pageContext.substitute_products[0]:pageContext.name}
        />
        <main className="site-wrapper-reveal">
            <Hero
                subtitle={pageContext.name + "er - et likestilt alternativ til"}
                title={(pageContext.substitute_products && pageContext.substitute_products[0] && pageContext.substitute_products[0].length > 0)?pageContext.substitute_products[0]:pageContext.name}
                description={pageContext.explanation}
                image={pageContext.image.asset}
                product={(pageContext.substitute_products && pageContext.substitute_products[0] && pageContext.substitute_products[0].length > 0)?pageContext.substitute_products[0]:pageContext.name}
                setFormActive={setFormActive}
            />

          {formActive &&
            <ProductForm
              setFormActive={setFormActive}
              formTitle={"Få tilbud på "+((pageContext.substitute_products && pageContext.substitute_products[0] && pageContext.substitute_products[0].length > 0)?pageContext.substitute_products[0]:pageContext.name).toLowerCase()}
              formDesc={"Fyll ut skjemaet nedenfor samt beskriv det konkrete garantibehovet nedenfor kort. Avhengig av kompleksitet tar vi kontakt for ytterligere detaljer før vi innhenter tilbud fra aktuelle leverandører i markedet."}
              formActive={formActive}
            />
          }


          <div style={{backgroundColor:'#fcfcfc'}}>
            <section style={{maxWidth:'1200px',marginLeft:'auto', marginRight:'auto'}}>
              <Aggregator
                product={(pageContext.substitute_products && pageContext.substitute_products[0] && pageContext.substitute_products[0].length > 0)?pageContext.substitute_products[0]:pageContext.name}
                setFormActive={setFormActive}
              />
            </section>
          </div>

          <Container>

            <Row>
              <Col lg={7}>
                {pageContext.content &&
                  <ArticleContent>
                    <BlockContent blocks={pageContext.content} serializers={serializers}/>
                  </ArticleContent>
                }
              </Col>
              <Col lg={5}>
                  <Row>
                    <Col>
                      <Aside>
                        <h2>Bakgrunn for {((pageContext.substitute_products && pageContext.substitute_products[0] && pageContext.substitute_products[0].length > 0)?pageContext.substitute_products[0]+"er":pageContext.name).toLowerCase()}</h2>
                        <BlockContent blocks={pageContext.background} serializers={serializers}/>
                      </Aside>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Aside>
                        <h2>Ulike tilbydere av {((pageContext.substitute_products && pageContext.substitute_products[0] && pageContext.substitute_products[0].length > 0)?pageContext.substitute_products[0]+"er":pageContext.name).toLowerCase()}</h2>
                        <BlockContent blocks={pageContext.providers} serializers={serializers}/>
                        <p>
                          Under er en liste med eksempler på aktører som potensielt kan tilby&nbsp;
                          {((pageContext.substitute_products && pageContext.substitute_products[0] && pageContext.substitute_products[0].length > 0)?pageContext.substitute_products[0]+"er":pageContext.name).toLowerCase()}
                          &nbsp;i det norske markedet:
                        </p>
                        <ul>
                          {
                            pageContext.provider.map((provider, k) => {

                              //console.log(provider)
                                return(
                                  <li key={"provider"+k}>
                                    <a href={"/"+provider.slug.current}>{provider.name}</a>
                                  </li>
                                )
                              }
                            )
                          }
                        </ul>
                      </Aside>
                    </Col>
                  </Row>

              </Col>
            </Row>
          </Container>

          <div style={{backgroundColor:'#ececec'}}>
          <section style={{maxWidth:'1200px',marginLeft:'auto', marginRight:'auto'}}>
            <Assurance
              product={(pageContext.substitute_products && pageContext.substitute_products[0] && pageContext.substitute_products[0].length > 0)?pageContext.substitute_products[0]:pageContext.name}
              setFormActive={setFormActive}
            />
          </section>

          <AboutServiceWrap>
            <AboutArea
              industries={data.industries}
              products={data.products}
              setFormActive={setFormActive}
            />
            {/*<ServicesArea industries={data.industries} products={data.products}/>*/}
          </AboutServiceWrap>
          </div>

        </main>


        <Footer
          product={(pageContext.substitute_products && pageContext.substitute_products[0] && pageContext.substitute_products[0].length > 0)?pageContext.substitute_products[0]:pageContext.name}
          setFormActive={setFormActive}
        />
    </Layout>
  )
}

export default Product
